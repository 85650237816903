//import logo from './logo.svg';
import logo from './501061.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
	<p>
	  Chuck Smith with an uplifting discussion on redemption!
	</p>
        <a
          className="App-link"
          href="https://twft.subspla.sh/cbdnbby"
          target="_blank"
          rel="noopener noreferrer"
        >
          Revelation (chapter 5) and the Book Of Ruth
        </a>
	<p></p>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          GotQuestions.org
        </p>
        <a
          className="App-link"
          href="https://www.gotquestions.org/redemption.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          What is the meaning of Christian redemption?
        </a>
      </header>
    </div>
  );
}

export default App;
